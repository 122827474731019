body[data-theme="light"] {
    @import '../src/scss/light-slider.scss';
 
    .slider-tab {
       background-color: #a1cfe9;
    }

    .resume {
        color: #4f90ae;
    }
}